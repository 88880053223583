var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.initializing
      ? _c(
          "div",
          [
            _c("h3", { staticClass: "primary--text mb-2" }, [
              _vm._v("Loading invoice ...")
            ]),
            _c("v-progress-linear", {
              attrs: {
                height: "10",
                color: "primary",
                rounded: "",
                indeterminate: ""
              }
            })
          ],
          1
        )
      : _c(
          "div",
          [
            _c("psi-page-title", {
              attrs: {
                title:
                  "Invoice for " + _vm.$_.get(_vm.invoice, "property.name", ""),
                icon: _vm.page.icon,
                breadcrumbs: _vm.breadcrumbs
              }
            }),
            _c(
              "v-card",
              { staticClass: "mt-4 px-3 py-5" },
              [_c("invoice-detail", { attrs: { invoice: _vm.invoice } })],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }