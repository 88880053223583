<template>
    <div>
        <div v-if="initializing">
            <h3 class="primary--text mb-2">Loading invoice ...</h3>
            <v-progress-linear
                height="10"
                color="primary"
                rounded
                indeterminate
            ></v-progress-linear>
        </div>
        <div v-else>
            <psi-page-title
                :title="`Invoice for ${$_.get(invoice, 'property.name', '')}`"
                :icon="page.icon"
                :breadcrumbs="breadcrumbs"
            ></psi-page-title>
            <v-card class="mt-4 px-3 py-5">
                <invoice-detail :invoice="invoice"></invoice-detail>
            </v-card>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "property-invoice",
    components: {
        InvoiceDetail: () =>
            import("@components/invoice/components/InvoiceDetail"),
    },
    props: {},
    data() {
        return {
            page: {
                icon: "mdi-receipt",
            },
            breadcrumbs: [
                {
                    text: "Invoicing",
                    disabled: false,
                    to: { name: "Invoicing" },
                },
                {
                    text: "Property Invoice",
                    disabled: true,
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Invoices", ["loading", "invoice"]),
        initializing() {
            return this.loading || Object.keys(this.invoice).length === 0;
        },
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler(to) {
                this.getInvoice(to.params.id);
            },
        },
    },

    methods: {
        ...mapActions("Invoices", ["getInvoice"]),
    },
};
</script>

<style scoped>
</style>